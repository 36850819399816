import './course.css'
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert'

// import '../General/first_row.css';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect, useParams} from "react-router-dom";
import {
    Chip,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, FormGroup, Snackbar, withStyles

} from "@mui/material";
import '../../App.css';
import { alpha, styled } from '@mui/material/styles';




import SearchCourseBar from "./SearchCourseBar";
import HeaderBar from "../utils/headerbar";
import InfoIcon from '@mui/icons-material/Info';
import GroupForm from "../groups/GroupForm2";
import JoinsRequests from "../groups/JoinsRequests";
import TasksList from "../tasks/TasksList";
import Information from "../utils/Information";
function CoursePage() {


    const { groupinfo,name,surname,email,degree,info,taskinfo,courseslist,studentDegree,degrees,group,curCourse } = useContext(AppContext);


    const [CourseList,SetCourseList] = courseslist
    const [StudentDegree,SetStudentDegree] = studentDegree
    const [Degrees,SetDegrees] = degrees
    const [CFU,SetCFU] = useState(false)
    const [Group,SetGroup] = group
    const [CurCourse,SetCurCourse] = curCourse
    const { id } = useParams();
    const [TaskInfo,SetTaskInfo] = taskinfo
    const [Info,SetInfo] = info
    const [GroupInfo,SetGroupInfo] = groupinfo
    const [GroupFormShow, SetGroupFormShow] = useState(true)
    const [JoinGroupShow,SetJoinGroupShow] = useState(false)
    const [PendingRequests,SetPendingRequests] = useState([])
    const [OpenDeclineModal,SetOpenDeclineModal] = useState(false)
    const [MemberToDelete,SetMemberToDelete] = useState(false)
    const [DeclineMotivation,SetDeclineMotivation] = useState("")
    const [Error,SetError] = useState(false)
    const [Email,SetEmail] = email
    const [Name,SetName] = name
    const [Surname,SetSurname] = surname
    const [Degree,SetDegree] = degree




    const MenuChip = styled(Chip)(({ theme }) => ({
        color: '#941010',
        backgroundColor: `white`,
        margin: '0 3px',
        fontSize: '1.15rem',
        border: '1px solid #941010',
        height: '45px',
        width: '80px',
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: '#941010',
            color: 'white'
        },
        '&:focus': {
            backgroundColor: '#941010',
            color: 'white'
        },
        '&:active': {
            backgroundColor: '#941010',
            color: 'white'
        }
    }));

    useEffect(()=>{

        axios.get("courses",{params:{type:'student',courseid:id}}).then(response=>{
            SetCourseList(response.data['courses'][0])

        }).catch(error=>console.log(error))

        axios.get("courses",{params:{type:'student',courseid:id}}).then(response=>{
            var res = response.data['courses'].filter(c => c.is_alias === false)
            SetCurCourse(res[0])

        }).catch(error=>console.log(error))
        axios.get('/group',{params:{courseid:id}}).then(response=>{
            if('name' in response.data['group']){
                SetGroup(response.data['group'])
            }

        })
        axios.get('/userinfo',{params:{courseid:id}}).then(response=>{
            SetName(response.data['name'])
            SetSurname(response.data['surname'])
            SetDegree(response.data['degree'])
        })


    },[])


    // useEffect(()=>{
    //     SetGroupInfo(false)
    // },[Group])



    useEffect(()=>{
        if(Group && CurCourse){
            axios.get('/pending-requests',{params:{groupid:Group.id,courseid:CurCourse.id}}).then(response=>{
                    SetPendingRequests(response.data['members'])
            })
        }
    },[Group,CurCourse])

    function addMember(e,member){
        e.preventDefault()
        axios.post('/accept_request',{groupid:Group.id,email:member,courseid:CurCourse.id}).then(response=>{
            var re = []
            PendingRequests.map(r => {
                if(r !== member){
                    re.push(r)
                }
            })
            SetPendingRequests(re)
            SetGroup(response.data['group'])
        }).catch(error=> {
            console.log(error)
            SetError('An error occurred')

        })

    }



    function declineInvitation(e){
        e.preventDefault()
        if(DeclineMotivation === "" || !DeclineMotivation || DeclineMotivation.length === 0){
            axios.post("/delete_request",{motivation:DeclineMotivation,member:MemberToDelete,courseid:CurCourse.id,groupid:Group.id})
                .then(response=>{
                    var re = []
                    PendingRequests.map(r => {
                        if(r !== MemberToDelete){
                            re.push(r)
                        }
                    })
                    SetPendingRequests(re)
                    SetOpenDeclineModal(false)
                })
                .catch(error=>{
                    console.log(error)
                    SetError('An error occurred')
                })
        }else{
            SetError("Please, provide a motivation")
        }

    }
    function handleCloseSnack(){
        SetError(false)
    }

    return (
        <div>
            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />
            <Dialog
                open={OpenDeclineModal && MemberToDelete}
                onClose={()=>{
                    SetOpenDeclineModal(false);
                    SetMemberToDelete(false)
                    SetDeclineMotivation("")
                }}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Decline join request of {MemberToDelete}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are declining the request of {MemberToDelete}.
                        Please, add a motivation. The professor(s) will be notified.
                        <br/>
                        <br/>
                        <br/>
                        <TextField
                            id="declinemotivation"
                            value={DeclineMotivation}
                            onChange={(e)=>SetDeclineMotivation(e.target.value)}
                            label="Motivation"
                            sx = {{width:"100%"}}
                            multiline
                            maxRows={4}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{
                        SetOpenDeclineModal(false);
                        SetMemberToDelete(false)
                        SetDeclineMotivation("")
                    }}>Cancel</Button>
                    <Button onClick={(e)=>declineInvitation(e)} disabled={DeclineMotivation === ""} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <HeaderBar />
            {(CurCourse && Email && Name && Surname && Degree) ? <div style={{textAlign:'center', margin:'2% 0'}}><h1 style={{margin:'2% 0'}}>{CurCourse.name}</h1>
                <Divider>
                    <div style={{display:"inline-block"}}>

                        <MenuChip sx={!GroupInfo?{backgroundColor:'white',color:'#941010'}:{backgroundColor:'#941010',color:'white'}}
                          onClick={()=> {
                            SetInfo(false)
                            SetTaskInfo(false)
                            SetGroupInfo(prev => !prev)

                        }} label="Group" />
                        <MenuChip sx={!TaskInfo?{backgroundColor:'white',color:'#941010'}:{backgroundColor:'#941010',color:'white'}}
                          onClick={()=> {
                            SetInfo(false)
                            SetGroupInfo(false)
                            SetTaskInfo(prev => !prev)

                        }} label="Tasks" />
                        <MenuChip sx={!Info?{backgroundColor:'white',color:'#941010'}:{backgroundColor:'#941010',color:'white'}}

                                  onClick={()=>
                                  {
                                      SetGroupInfo(false)
                                      SetTaskInfo(false)
                                      SetInfo(prev=>!prev);

                                  }} label="Info" />
                    </div>


                </Divider>

                {PendingRequests.length > 0 && <div>
                    {PendingRequests.map(request=><div style={{padding:'2%'}}>
                        <Alert
                            action={
                                <div>
                                    <Button color="inherit" size="small" onClick={(e)=>addMember(e,request)}>
                                        Accept
                                    </Button>
                                    <Button color="inherit" size="small" onClick={()=>{SetMemberToDelete(request);SetOpenDeclineModal(true)}}>
                                        Decline
                                    </Button>
                                </div>

                            }
                        >
                            <b>{request}</b> asked to join your group
                        </Alert></div>


                    )}


                </div>}
                <Collapse in={Info}>
                    <div className={'groupcard'}>

                    <Information />


                    </div>
                </Collapse>
                <Collapse in={GroupInfo}>
                    <div className={'groupcard'}>
                        {/*<div style={{margin: '1% 0'}}>You have not joined a group yet. You can join an existing group for this course, or create a new one.</div>*/}


                        {CurCourse.groupdeadline === false && !Group && GroupFormShow &&
                            <div>Don't have a group yet? <Button variant='contained' color={'error'}
                                                                 onClick={()=> {
                                                                             SetJoinGroupShow(prev => !prev);SetGroupFormShow(false)}}
                                                                     >Join a group here</Button></div>}
                        {CurCourse.groupdeadline === false && !Group && JoinGroupShow &&
                            <div><Button variant='contained'
                                                                 onClick={()=> {
                                                                     SetGroupFormShow(prev => !prev);SetJoinGroupShow(false)}}
                            >Create a group here</Button></div>}

                        <div style={{margin: '1vh 2vw'}}>
                            {JoinGroupShow ? <JoinsRequests/> : <GroupForm/> }
                        </div>

                    </div>


                </Collapse>
                <Collapse in={TaskInfo}>
                    <TasksList/>
                </Collapse>

            </div> : <div className={'circularbar'}><CircularProgress /></div>}
        </div>
    );
}


export default CoursePage;
