import './App.css';
import ReactDOM from "react-dom";
import React, {useState, useEffect, useContext, createContext} from "react";
import axios from "axios";
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Login from "./components/login/Login";

import SignUp from "./components/login/Signup";


import Axios from "axios";
import Reset from "./components/login/Reset";
import ResetForm from "./components/login/ResetForm";
import CoursesList from "./components/courses/CoursesList";
import HomePage from "./components/pages/HomePage";
import CoursePage from "./components/courses/CoursePage";
import {CircularProgress} from "@mui/material";
import GroupForm from "./components/groups/GroupForm";
import Profile from "./components/utils/Profile";
import CoursesListProfessor from "./components/courses/CoursesListProfessor";
import CoursePageProfessor from "./components/courses/CoursePageProfessor";
import CreateCourse from "./components/courses/CreateCourse";
import TasksListProfessor from "./components/tasks/TasksListProfessor";
import TasksList from "./components/tasks/TasksList";
import Submissions from "./components/tables/Submissions";
import GroupsList from "./components/groups/GroupsList";
import StudentsList from "./components/groups/StudentsList_1";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.baseURL = window.location.origin+'/';


export const AppContext = createContext('')

function App() {
    const [Tasks,SetTasks] = useState(false)
    const [Email, SetEmail] = useState(false)
    const [AttendedCourses,SetAttendedCourses] = useState(false)
    const [AcademicYears,SetAcademicYears] = useState(false)
    const [CoursesArr,SetCoursesList] = useState(false)
    const [StudentDegree,SetStudentDegree] = useState(false)
    const [Course,SetCourse] = useState(false)
    const [Degrees,SetDegrees] = useState(false)
    const [Group,SetGroup] = useState(false)
    const [CurCourse,SetCurCourse] = useState(false)
    const [UserType,SetUserType] = useState(false)
    const [Username,SetUsername] = useState(false)
    const [TaskInfo,SetTaskInfo] = useState(true)
    const [Info,SetInfo] = useState(false)
    const [GroupInfo,SetGroupInfo] = useState(false)

    const [Name,SetName] = useState(false)
    const [Surname,SetSurname] = useState(false)
    const [Degree,SetDegree] = useState(false)

    useEffect(()=>{
        SetGroup(false)
        SetCurCourse(false)

        if(!Email || !UserType){
            var email = ''
            var user_ty = ''

            axios.get('/get_session').then(response=>{
                if(response.data['email'] !== "None" && response.data['email']){
                    SetEmail(response.data['email'])
                    SetUserType(response.data['user_type'])

                }

            })

            if(email !== '' && user_ty !== '' && !AttendedCourses){
                axios.get("courses",{params:{type:window.user_type}}).then(response=>{
                    SetAttendedCourses(response.data['courses'])
                }).catch(error=>console.log(error))
            }
        }

    },[Email,UserType])










    useEffect(()=>{

        console.log('username',window.username,window.user_type)
        if(!Email && window.email && window.email !== "None"){
            SetEmail(window.email)
        }
        if(!Username && window.username && window.username !== "None"){
            SetUsername(window.username)
        }
        if(!UserType && window.user_type && window.user_type !== "None"){
            SetUserType(window.user_type)
        }



    },[])


    return (
        <div className="App" id='app' >

            <AppContext.Provider value={{tasks:[Tasks,SetTasks],taskinfo:[TaskInfo,SetTaskInfo],usertype:[UserType,SetUserType],info:[Info,SetInfo],groupinfo:[GroupInfo,SetGroupInfo],name:[Name,SetName],surname:[Surname,SetSurname],degree:[Degree,SetDegree],
                    username:[Username,SetUsername],email:[Email,SetEmail],curCourse:[CurCourse,SetCurCourse],group:[Group,SetGroup],studentCourses:[AttendedCourses,SetAttendedCourses], academicYears:[AcademicYears,SetAcademicYears],
                courseslist:[CoursesArr,SetCoursesList], course:[Course,SetCourse],    studentDegree:[StudentDegree,SetStudentDegree],degrees:[Degrees,SetDegrees],


            }}
                // showSnackReport:[ShowSnackReport,SetShowSnackReport]AllMentions]}}
            >

                {<Router>

                    <div >


                        <Switch>

                            <Route path="/login">

                                <Login />
                            </Route>

                            <Route path="/password_reset/:token" exact>
                                <ResetForm />
                            </Route>
                            <Route path="/password_reset">
                                <Reset message={window.errorMessage}/>
                            </Route>

                            <Route path="/register">
                                <SignUp />
                            </Route>

                            <Route path="/my-courses" exact>
                                {/*<CoursesList/>*/}
                                {/*<div>{UserType}</div>*/}
                                {UserType ? <>

                                    {UserType === 'student' && <CoursesList/>}
                                    {UserType === 'professor' && <CoursesListProfessor/>}
                                    </> : <><div className={'circularbar'}><CircularProgress /></div></>
                                }
                            </Route>
                            <Route path="/courses/:id" exact>
                                {/*<CoursePage/>*/}
                                {UserType ? <>

                                    {UserType === 'student' && <CoursePage/>}
                                    {UserType === 'professor' && <CoursePageProfessor/>}
                                </> : <><div className={'circularbar'}><CircularProgress /></div></>
                                }
                            </Route>
                            <Route path="/create_course" exact>
                                <CreateCourse />
                            </Route>

                            <Route path="/profile" exact>
                                <Profile />
                            </Route>
                            <Route path="/task/:id" exact>

                                <TasksListProfessor />
                            </Route>
                            <Route path="/evaluate-task/:id" exact>
                                {/*<div>SUBMISSIONS</div>*/}
                                {UserType === 'professor'  && <Submissions />}
                                {UserType === 'student' && <div style={{margin:'10%'}}><h1>FORBIDDEN</h1></div>}
                            </Route>
                            <Route path="/group/:id" exact>

                                {UserType === 'professor' && <GroupsList />}
                                {UserType === 'student' && <div style={{margin:'10%'}}><h1>FORBIDDEN</h1></div>}
                            </Route>
                            <Route path="/students/:id" exact>

                                {UserType === 'professor' &&  <StudentsList />}
                                {UserType === 'student' && <div style={{margin:'10%'}}><h1>FORBIDDEN</h1></div>}
                            </Route>

                            <Route path="/">

                                <HomePage />
                            </Route>
                            <Route path="">

                                <HomePage />
                            </Route>


                        </Switch>
                    </div>
                </Router>}

            </AppContext.Provider>
        </div>
    );
}




export default App;
const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);